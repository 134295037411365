.container.experience__container {
    width: var(--container-width-lg);
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 1rem;
    /* padding: 2.4rem 5rem; */
    justify-content: center;
    border-radius: 2rem;
    border: 1px solid transparent;
    /* max-width: 100%; */
    display: block;
    /* background-color: red; */
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__container > div h3 {
    color: var(--color-primary);
    text-align: center;
    margin-bottom: 2rem;;
}

.experience__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* grid-template-columns: 1fr 1fr; */
    /* row-gap: 2rem; */
}

.experience__details {
    display: flex;
    gap: 1rem;
    margin: .25rem 1rem;
    padding: .3rem;
    align-items: center;
}

.experience__details small {
    font-size: 0.7rem;
    color: var(--color-light)
}
.experience__details h4 {
    /* margin-bottom: 0; */
    line-height: 0.7rem;
}

.experience__icon {
    /* margin-top: 6px; */
    font-size: 2.5rem;
    color: var(--color-primary);
    align-self: center;
}


/* ======== Responsive ======== */

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
    .experience__container {
        width: var(--container-width-md);
    }

    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 600px) {
    .experience__container {
        width: var(--container-width-sm);
    }
    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;

    }
}