header {
    /* min-height: 100vh; */
    padding-top: 7rem;
    overflow: hidden;
    /* max-height: 100% !important; */
}

.header__container {
    text-align: center;
    height: 100%;
    max-height: max-content;
    position: relative;
}

/* ======== CTA ======== */

.cta {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    gap: 1.2rem;
}

/* ======== Socials ======== */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    z-index: 100;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ======== Image  Contain Style ======== */

.my_image_container {

    margin-top: 4rem;
    overflow: hidden;
}

.my_image, #profile {
    width: 24rem;
    height: 40rem;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    position: relative;
    border-radius: 12rem 12rem 0 0;
    


}

.my_image {
    
    /* background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuULIV4rH_j2GMsAHHGI0C9OwxiiENErpEjrHcVRhnHwnMYrsprROBJEp3vXnI4qucvtU&usqp=CAU'); */
    background-image: url('https://camo.envatousercontent.com/11bd8a5c31d05df78eb32e625ac50de280263ad9/68747470733a2f2f6d656469612e67697068792e636f6d2f6d656469612f32366e36584a646c36344c51426c4f43732f67697068792e676966');

    /* position: absolute; */
    margin-top: 4rem;
    
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    z-index: 1;
    display: block;

}

#profile {
    background: linear-gradient(var(--color-primary), transparent);
    padding-top: 5rem;
    z-index: 2;
    object-fit: cover;
}

/* ======== Image  Overflow Style ======== */

/* .my_image_container {
    height: 40rem;
    margin-top: 4rem;
    overflow: hidden;
    background-color: red;
} */

/* .my_image {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 40rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    z-index: 1;
    display: block;

} */

/* #profile {
    position: relative;
    left: 50%;
    transform: translateX(-48%);
    width: 22rem;
    bottom: 0px;
    margin-top: 4rem;
    top: 4rem;
    height: 36rem;
    z-index: 2;

    margin-bottom: 1rem;
    object-fit: scale-down;
} */

/* ======== Scroll Down ======== */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    z-index: 100;
}


/* ======== Responsive ======== */

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
    .container {
        height: 68vh;
    }
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 600px) {
    .container {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}