.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent );
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(0deg);
}


.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

/* .about__card,  */
.about__slider {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    /* padding: 2rem; */
    text-align: center;
    transition: var(--transition);
    height: 14rem;
}

.about__card {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 12rem;
    justify-content: space-evenly;
}

.about__card > small {
    justify-self: flex-end;
    padding: 0 1rem;
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 2rem;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light)
}

.about__content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

.card__logo {
    padding: 0.5rem 3rem;
    object-fit: contain;
    max-height: 5rem;
    align-self: flex-start;

}

.detail__line__flex {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;


    margin-top: 0.5rem;
    justify-content: center;
    align-items: center;
}

.detail__line__grid {
    display: grid;
    padding: 0 1rem;
    grid-template-columns: 40% 10% 40%;
    gap: 5%;

    margin-top: 0.5rem;
    justify-content: center;
    align-items: center;
}

.about__slider {
    background-color: rgba(0,0,0,0.4);
    backdrop-filter: blur(15px);
    
}

.white-logo {
    filter: brightness(100) saturate(0);
}

.detail__line__udacity-links{
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-rows: 48% 48%;
    gap: 4%;
    margin: 0 1rem;
    
}
.udacity_link {
    font-size: .7rem;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

.swiper-button-prev::after, .swiper-button-next::after{
    color: var(--color-primary);
}


/* ======== Responsive ======== */

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
    .about__container {
        /* grid-template-columns: 1fr; */
        /* gap: 0; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}