.container.testimonials__container {
    width: 60%;
    padding-bottom: 3rem;
    padding-left: 4rem;
    padding-right: 4rem;
    /* background: white; */
}

.testimonial__image {
    width: 6rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);

}

.testimonial {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    /* max-height: 40rem; */
    overflow: hidden;
}

.testimonial__text__container {
    height: 18rem;
    max-height: 20rem;
    overflow: scroll;
    scroll-behavior: auto;
    
}

.testimonial__text {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.testimonial__name {
    font-size: 1.2rem;
}

.testimonial__link {
    font-size: 0.8rem;
}


.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

.swiper-button-prev::after, .swiper-button-next::after{
    color: var(--color-primary);
}
/* .swiper-pagination {
    background: white;
    max-width: max-content;

} */

/* ======== Responsive ======== */

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
    .container.testimonials__container  {
        width: 60%;
    }
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 600px) {
    .container.testimonials__container  {
        width: var(--container-width-sm);
    }

    .testimonial__text {
        width: var(--container-width-sm);
    }
}




/* .swiper {
  width: 100%;
  height: 100%;
} */

/* .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  Center slide text vertically
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
} */

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
