.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    height: fit-content;
    justify-content: center;
    align-items: center;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-top {
    position: relative;
}

.portfolio__item-image, .portfolio__item-cta {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 10rem;
    object-fit: cover;
}

.portfolio__item h3 {
    margin: 1.2rem 0 0.5rem 0;
    text-align: center;
    font-size: 2rem;
}

.portfolio__item-cta {
    display: none;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    z-index: 200;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.6);
    height: 10rem;
    position: absolute;
    top: 0;
    width: 100%;
    transition: var(--transition);
    
}

.portfolio__item:hover .portfolio__item-cta {
    display: flex;
    
}

.description {
    font-size: 0.8rem;
    color: var(--color-light);
}


.skills {
    padding: 0.2rem 1rem 0.8rem 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
    justify-content: center;
    align-items: baseline;
    margin: 0 auto;
}

.skills > .service_icon {
    /* font-size: var(--service-icon-size); */
    font-size: 1.5rem;
    color: var(--service-icon-color);
}

.skills > .svg_icon {
    /* width: var(--service-icon-size); */
    /* height: var(--service-icon-size); */
    height: 1.5rem;
    fill: var(--service-icon-color);
}

/* ======== Responsive ======== */

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

}

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 600px) {

    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}