.services__container {
    display: grid;
    grid-template-columns: repeat(3, 30vw);
    gap: 2rem;
    height: fit-content;
    justify-content: center;
}

.service {
    background: var(--color-bg-variant);
    border-radius: 2rem 0;
    border: 1px solid transparent;
    /* border: 1px solid var(--color-primary); */
    /* height: fit-content; */
    height: auto;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* overflow: hidden; */
    /* min-height: min-content; */
    /* padding-bottom: 4rem; */
    position: relative;
    /* max-width: 30%; */
}

.service:hover {
    background: rgba(0,0,0,0.3);
    backdrop-filter: blur(3px);
    border-color: var(--color-primary);
    /* border-color: var(--color-primary-variant); */
    cursor: default;

}

.service__head {
    background: black;
    /* background: var(--color-primary); */
    border-radius: 2rem 0;
    padding: 2rem;
    box-shadow: 0 2rem 1rem rgba(0,0,0,0.1) ;
    z-index: 5;
    transition: var(--transition);
}

.service__head h3 {
    /* color: var(--color-bg); */
    color: var(--color-primary);
    /* color: black; */
    font-size: 1.3rem;
    text-align: center;
    z-index: 10;
}

.service:hover .service__head {
    background-color: var(--color-primary);
}

.service:hover h3 {
    /* color: var(--color-white); */
    color: black
}

.service__description {
    padding: 1rem;
    font-size: 0.8rem;
}

.service__list {
    padding: 0 2rem 0.5rem 2rem;
}

.service__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;

}

.service__list-icon {
    margin-top: .3rem;
    color: var(--color-primary);
}

.service__list p {
    font-size: 0.9rem;;
}


.tools {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-size: 1.2rem;
    justify-content: center;
    align-items: baseline;
    margin: 0 auto;
    /* position: absolute; */
    /* bottom: 0; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    max-width: 28vw;
}

.service_icon {
    font-size: var(--service-icon-size);
    color: var(--service-icon-color);
}

.svg_icon {
    /* width: var(--service-icon-size); */
    height: var(--service-icon-size);
    fill: var(--service-icon-color);
}

/* ======== Responsive ======== */

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
    .services__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .service {
        height: auto;
    }
    .tools {
        max-width: 48vw;
    }
}

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 600px) {

    .services__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    .tools {
        max-width: 90vw;
    }
}