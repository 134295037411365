.container.contact__container {
    /* width: 58%; */
    display: grid;
    grid-template-columns: 32% 58%;
    gap: 10%;
}

.contact__options {
    display: flex;
    flex-direction:  column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    overflow: auto;
    overflow-wrap: normal;
}

.contact__option:hover {
    /* background: transparent; */
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
    border-color: var(--color-primary-variant);
}

.show > .show__prompt {
    display: block;
}
.show:hover > .show__prompt {
    display: none;
}

.show > .show__detail {
    display: none;
}
.show:hover > .show__detail {
    display: block;
}

.contact__option-icon {
    font-size: 2rem;
    /* margin-bottom: 0.1rem; */
}

.contact__option a {
    /* margin-top: 0.5rem; */
    display: inline-block;
    font-size: 0.8rem;
}

.contact__option p {
    margin-top: 0.65rem;
    display: inline-block;
    font-size: 0.8rem;
}

.linkedin {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

#name {
    font-weight: 600;
    font-size: medium ;
}

.linkedin > p {
    font-size: x-small;
    line-height: 0rem;
}

.li_logo {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    gap: 0.1rem;
    user-select: none;
    font-size: large;
}
#li_logo {
    transform: translateY(15%);
    font-size: larger;
}

.li_img {
    width: 5rem;
    border-radius: 50%;
    justify-items: center;
    margin: auto;
    margin-top: 0.5rem;
}

.icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
}


/* FORM */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
    /* background: transparent; */
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

/* ======== Responsive ======== */

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}