nav {
    background: rgba(255, 255, 255, 0.3);
    /* background: rgba(0, 0, 0, 0.3); */
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 100;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);


}

nav .a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
    width: max-content;
    transition-delay: 0.05s;
}

nav .a:hover {
    background: rgba(0, 0, 0, 0.3);
}

nav .a.active {
    background: var(--color-primary);
    color: var(--color-bg);

}


/* Tooltips */
.link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.link > span.text {
    display: none;
    
}

nav > .link:hover > .text {
    font-size: 0.7rem;
    display: block;
    position: absolute;
    text-align: center;


    bottom: 0.1rem;
    margin: 0.1rem 0;

    
}

/* ======== Responsive ======== */

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
    nav > .link:hover > .text {
        bottom: -0.1rem;
    }

}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 600px) {
    nav {
        max-width: 95vw;
        padding: 1rem 1.4rem;
    }

    nav .a {
        padding: 0.5rem;
    }
}